import React from 'react';

import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import { graphql, HeadProps, PageProps } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';

import {
  Bio,
  Layout,
  Seo,
  CurationList,
  ContentWrapper,
  Masthead,
  MainMasthead,
  Newsletter,
  OnlyProduction,
  Adsense,
} from '@/components';
import { useLatestFivePosts, useMetadata } from '@/hooks';

const BlogIndex = ({
  data,
}: PageProps<{
  background: ImageDataLike;
}>) => {
  const { background } = data;
  const curations = useLatestFivePosts();
  const metadata = useMetadata();

  return (
    <Layout>
      <Masthead background={background}>
        <MainMasthead
          title={metadata.title}
          description={`
            Be professional, Be communicative, Be inspirational.
          `}
        />
      </Masthead>

      <ContentWrapper
        component="main"
        css={css`
          padding-top: 2rem;
          padding-bottom: 2rem;
        `}
      >
        <Bio />
        <Divider />
        <Adsense />
        <Divider />
        <CurationList
          title="최신 포스트를 살펴보세요"
          description="새로운 소식"
          curations={curations}
          moreLink="/posts"
        />
      </ContentWrapper>

      <OnlyProduction>
        <Newsletter />
      </OnlyProduction>
    </Layout>
  );
};

export default BlogIndex;

export const backgroundQuery = graphql`
  query {
    background: file(relativePath: { eq: "Hero.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export const Head = ({ location }: HeadProps) => {
  return <Seo path={location.pathname} />;
};
